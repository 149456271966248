export const regionRestrictions = [
    {
        region: "Paphos",
        location: {
            lat: 34.785565,
            lng: 32.428099
        },
        radius: 10000
    },

    {
        region: "Limassol",
        location: {
            lat: 34.671987,
            lng: 33.038332
        },
        radius: 10000
    },
    {
        region: "Nicosia",
        location: {
            lat: 35.171987,
            lng: 33.038332
        },
        radius: 10000
    },
    {
        region: "Larnaca",
        location: {
            lat: 34.916667,
            lng: 33.633333
        },
        radius: 10000
    },
    {
        region: "Ammochostos",
        location: {
            lat: 35.123333,
            lng: 33.873333
        },
        radius: 10000
    },
    {
        region: "Greece",
        location: {
            lat: 39.074208,
            lng: 21.824312
        },
        radius: 10000
    }
    

];


export const getRegionRestrictions = (region) => {
    switch (region) {
        case "Paphos":
            return {
               
                componentRestrictions: { country: ["CY"] }, // Restrict to Cyprus
            };

        case "Limassol":
            return {
           componentRestrictions: { country: ["CY"] },
            };
        case "Nicosia":
            return {
                location: { lat: 35.171987, lng: 33.038332 }, // Coordinates for Nicosia, Cyprus
                radius: 20000,
                componentRestrictions: { country: ["CY"] },
            };
        case "Larnaca":
            return {
                location: { lat: 34.916667, lng: 33.633333 }, // Coordinates for Larnaca, Cyprus
                radius: 20000,
                componentRestrictions: { country: ["CY"] },
            };
        case "Ammochostos":
            return {
                location: { lat: 35.123333, lng: 33.873333 }, // Coordinates for Ammochostos, Cyprus
                radius: 10000,
                componentRestrictions: { country: ["CY"] },
            };

        case "Greece":
            return {
                componentRestrictions: { country: ["GR"] },
            };
        default:
            return {
                componentRestrictions: { country: ["CY"] }, // Default to Cyprus and Greece
            };
    }
};