import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

// export function signupAction(email, password, history) {
//     return (dispatch) => {
//         signUp(email, password)
//         .then((response) => {
//             saveTokenInLocalStorage(response.data);
//             runLogoutTimer(
//                 dispatch,
//                 response.data.expiresIn * 1000,
//                 history,
//             );
//             dispatch(confirmedSignupAction(response.data));
//             history.push('/');
//         })
//         .catch((error) => {
//             const errorMessage = formatError(error.response.data);
//             dispatch(signupFailedAction(errorMessage));
//         });
//     };
// }

export function logout(history) {
  console.log("ye logout action call go rha hai");
  localStorage.removeItem("userDetails");
  history.push("/");
  return {
    type: LOGOUT_ACTION,
  };
}
export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        console.log(response, "login response");
        saveTokenInLocalStorage(response.data.data.token?.token);
        localStorage.setItem(
          "isVerified",
          response.data?.data?.vendor?.isVerified
        );
        dispatch(loginConfirmedAction(response?.data?.data?.token?.token));
        history.push("/dashboard");
        //window.location.reload();
      })
      .catch((error) => {
        const errorMessage = formatError(error?.response?.data?.message);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(data) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload: data,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
