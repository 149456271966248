/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }

  // Add new method to close sidebar
  closeSidebar = () => {
    const wrapper = document.querySelector("#main-wrapper");
    if (wrapper.classList.contains("menu-toggle")) {
      wrapper.classList.remove("menu-toggle");
    }
  }

  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      winery = ["winery-management"],
      booking = ["booking-management"];
    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/" onClick={this.closeSidebar}>
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${winery.includes(path) ? "mm-active" : ""}`}>
              <Link to="winery-management" className="ai-icon" onClick={this.closeSidebar}>
                <i className="flaticon-381-photo"></i>
                <span className="nav-text">Experience management</span>
              </Link>
            </li>
            <li className={`${booking.includes(path) ? "mm-active" : ""}`}>
              <Link to="booking-management" className="ai-icon" onClick={this.closeSidebar}>
                <i className="flaticon-381-bookmark-1"></i>
                <span className="nav-text">Bookings</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
